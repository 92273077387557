import styled from 'styled-components';
import { Col, Row, Container, Navbar } from 'react-bootstrap';
import { Link } from 'gatsby';

export const AboutContainer = styled(Container)`
  width: 1200px !important;
  max-width: 1200px !important;
  @media (max-width: 1200px) {
    max-width: 960px !important;
  }
  @media (max-width: 992px) {
    max-width: 728px !important;
  }
  @media (max-width: 767px) {
    width: 100% !important;
  }
  @media print {
    margin: 0 !important;
    width: 100vw !important;
    max-width: 100vw !important;
  }
`;

export const LeftContainer = styled.div`
  float: left;
  width: 280px;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const RightContainer = styled.div`
  float: right;
  width: 920px;
  padding: 40px 0 55px 55px;

  @media (max-width: 1199px) {
    width: 680px;
  }
  @media (max-width: 991px) {
    float: none;
    width: 728px;
    padding: 25px 0 55px 0;
  }
  @media (max-width: 767px) {
    float: none;
    width: 100%;
    padding: 25px;
  }
`;

export const SearchTitle = styled(Col)`
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Figtree-Light', arial, sans-serif;
  font-weight: normal !important;
  color: #4d1367;
  font-size: 45px;
  line-height: 50px;
  @media (max-width: 767px) {
    font-size: 35px;
    line-height: 40px;
  }
`;

export const InputSearch = styled.input`
  background: #e9dfee;
  padding: 15px;
  border: none;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 14px;
  line-height: 14px;
  color: #666;
  margin-right: 5px;
`;

export const InputSubmit = styled.input`
  background: #6e2a8d;
  padding: 16px;
  border: none;
  font-family: 'Figtree-Medium', arial, sans-serif;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #fff;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
`;

export const LinkStyle = styled.a`
  float: right;
  position: relative;
  padding: 6px 50px 0 0;
  margin: 0;
  text-align: right;
  color: #ac85d9;
  line-height: 18px;
  text-decoration: none;
  @media (max-width: 767px) {
    margin: 20px 0 0 0;
    float: left;
    padding: 12px 0 0 50px;
    text-align: left;
  }
  :hover {
    color: #6e2a8d;
  }
`;

export const SearchAnchor = styled.a`
  word-wrap: break-word;
`;

export const BreakTag = styled.br`
  @media (min-width: 1285px) {
    display: none;
  }
`;

export const LocationImage = styled.img`
  position: absolute;
  width: 31px;
  height: auto;
  top: 0;
  right: 0;
  @media (max-width: 767px) {
    top: 5px;
    left: 0;
    width: 31px !important;
  }
`;

export const SearchResult = styled.div`
  margin-top: 15px;
  border-bottom: 1px solid #e5e5e5;
`;
export const SearchResults = styled.div`
  border-top: 1px solid #e5e5e5;
  margin-top: 10px;
  &:before {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`;

export const ResultAnchor = styled.a`
  text-decoration: none;
  color: #c77dec;
  font-size: 26px;
  font-weight: 300 !important;
  font-family: 'Figtree-Light', arial, sans-serif;
  webkit-transition: color 0.15s ease-out;
  -moz-transition: color 0.15s ease-out;
  -o-transition: color 0.15s ease-out;
  -ms-transition: color 0.15s ease-out;
  transition: color 0.15s ease-out;
  :hover {
    color: #6e2a8d;
  }
`;

export const ResultDesc = styled.p`
  word-wrap: break-word;
  margin: 0 0 30px 0;
  padding: 0;
  margin-top: 4px;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-position: top center;
  color: #666;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
`;

export const PageLink = styled.a`
  font-size: 12px;
  text-decoration: none !important;
  color: #999;
  margin: 2px 0px 8px 0px;
  padding: 0px;
  display: block;
  font-size: 12px;
  color: #999;
  margin: 2px 0px 8px 0px;
  padding: 0px;
  display: block;
`;

export const NoResults = styled.div`
  margin: 0;
  padding: 0;
  font-weight: bold;
  background-position: top center;
  color: #666;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  margin-top: 32px;
`;

export const PaginationDiv = styled.div`
  text-align: center;
  position: relative;

  .pagination {
    justify-content: center;
    display: flex;
    position: relative;
    gap: 8px;
    .first {
      position: absolute;
      top: 0;
      left: 0;
    }
    .last {
      position: absolute;
      top: 0;
      right: 0;
    }
    .activeLinkClass {
      pointer-events: none;
      color: #666;
      font-family: 'Figtree-Bold', arial, sans-serif;
    }
    .disabled {
      a {
        color: #666;
        pointer-events: none;
      }
    }
  }
`;

export const prev = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  margin-right: 4px;
  cursor: pointer;
`;
export const next = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  margin-right: 0px !important;
  cursor: pointer;
`;
